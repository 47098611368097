
import Button from "components/Button";
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

const KidsnFun = () => {
  return (
    <article className="w-full">
      <PageTitle>Děti a zábava pro malé i velké</PageTitle>

      <Helmet>
        <title>Děti a zábava pro malé i velké | {basics.appTitle}</title>
        <meta property="og:title" content="Děti a zábava pro malé i velké" />
      </Helmet>

      <div className="content-block">

<p>Protože pro nás děti nejsou žádnou překážkou. V sobotu i v neděli bude k dispozici dětský koutek s dozorem. Najdeš tam hračky, tvoření i místo k válení. Pokud to vaše dítko zvládá, rádi se o něj postaráme. Ale pokud není zvyklé být bez vás, můžete zde strávit čas i společně. Preferujeme děti bez doprovodu od 3 let.</p>

<ul>
<li>promítání pohádek</li>
<li>tvořivé dílničky</li>
<li>zdravá sváča a voda</li>
<li>záchody za rohem</li>
<li>klidný kout pro přebalení i nakojení</li>
<li>přenos z hlavního sálu</li>
</ul>

<p>Pokud se chystáte brát s sebou dítko, dejte nám prosím vědět - v registraci, pro dotazy se obraťte na <a href="mailto:katka.michalkova@pirati.cz">katka.michalkova@pirati.cz</a> </p>

<h4>Kam s dětmi vyrazit:</h4>

<ul>
<li>Björnsonův sad - nejbližší venkovní hřiště s kavárnou</li>
<li>hřiště na Sadech Národního odboje - Šelepka</li>
<li><a href="https://www.hvezdarna.cz/">Hvězdárna a planetárium Brno</a></li>
<li><a href="https://www.zoobrno.cz">ZOO Brno</a></li>
<li><a href="https://vida.cz/">VIDA! Centrum</a></li>
<li><a href="https://www.mamakafe.cz/">Mama Kafé</a></li>
<li><a href="http://www.detskacajovna.cz/">Dětská čajovna Mezi stromy</a></li>
</ul>

<p>Připravujeme pro vás Šifrovačku</p>

      </div>
    </article>
  );
};

export default KidsnFun ;
