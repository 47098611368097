import Button from "components/Button";
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

// import mapa_pdf from "../cf2023_restaurace.pdf";

const Catering = () => {
  return (
    <article className="w-full">
      <PageTitle>Stravování</PageTitle>

      <Helmet>
        <title>Stravování | {basics.appTitle}</title>
        <meta property="og:title" content="Stravování" />
      </Helmet>

      <div className="content-block">
        <h2>Stravování v Domě Kultury během zasedání</h2>
        <p>
          Sobotní oběd v restauraci, která je součástí objektu Domu Kultury, si budete moci rezervovat dopředu v&nbsp;rámci registračního formuláře pro účast na CF.
          Jelikož jsou kapacity restaurace omezeny na výdej cca 100 jídel během 45 minut, vytvořili jsme čtyři časové sloty (11.00–11.45, 11.45–12.30, 12.30–13.15, 13.15–14.00) pro možnost objednání oběda a&nbsp;žádáme všechny účastníky, aby se je snažili dodržovat.
          Zabráníme tak návalu a&nbsp;nutnosti vyhledat jiné stravovací zařízení ve špičce v&nbsp;rámci pauzy na oběd. Budeme ale schopni garantovat oběd pro 400 účastníků.<br/><br/>
          Ta samá restaurace Vám bude k dispozici i&nbsp;v době večeří, ale tu už si každý bude řešit individuálně bez předchozího objednání.<br/><br/>
          Krom toho bude možné se stravovat v&nbsp;okolních restauračních zařízeních nebo fastfoodech v&nbsp;objektu sousedícího nákupního centra Fórum.<br/>
        </p>
        <p>
          Pro činovníky a&nbsp;pozvané hosty bude zajištěno mimoobědové občerstvení.
        </p>

      </div>

      <Button
              className="md:text-xl btn--to-blue-300 mt-8 mb-8"
              bodyClassName="whitespace-no-wrap"
              color="black"
              icon="ico--chevron-right"
              href="https://www.google.com/maps/d/embed?mid=18lUu_hFIhaX42n8zivfkG3osLtui35g&ehbc=2E312F"
              target="_blank"
            >
              Mapa restaurací v okolí zasedání
      </Button>

      <div className="content-block">

        <h2>Stravování v Kině Varšava během nedělních workshopů</h2>
        <p>
          Po celou dobu konání bude otevřená kavárna a&nbsp;občerstvení ve foyer kina.<br/>
          V pauze na oběd lze využít restauraci s&nbsp;pizzerií Maškovka, restauraci Radniční sklípek, Kebab přímo naproti kinu Varšava nebo fastfood v&nbsp;nákupním centru Plaza.<br/>
        </p>
        <p>
          Pro činovníky a&nbsp;pozvané hosty bude zajištěno mimoobědové občerstvení.
        </p>

      </div>

{/*
      <Button
              className="md:text-xl btn--to-blue-300 mt-8 mb-8"
              bodyClassName="whitespace-no-wrap"
              color="black"
              icon="ico--chevron-right"
              href={mapa_pdf}
              target="_blank"
            >
              Mapa restaurací v okolí konání workshopů
      </Button>
*/}

    </article>
  );
};

export default Catering;
