import React from "react";
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics, partyBureauCandidates } from "config";

const LeaderElection = () => {
  const isLastItem = (idx) => partyBureauCandidates.length - 1 === idx;

  return (
    <article className="space-y-8 w-full">
      <Helmet>
        <title>Volba předsednictva | {basics.appTitle}</title>
        <meta property="og:title" content="Volba předsednictva strany" />
      </Helmet>

      <PageTitle>Volba předsednictva</PageTitle>

      <div className="alert alert--light flex-col content-block">
        <h3>Způsob volby předsednictva</h3>
        <p>
          Veškeré procesní detaily jsou k dispozici{" "}
          <a href="https://a.pirati.cz/cf2024/1_b_Hlasovac%C3%AD%20postup.pdf">zde</a>.
        </p>
      </div>

      {partyBureauCandidates.map((postClass, idx) => (
        <React.Fragment key={postClass.postTitle}>
          <section>
            <h2 className="head-heavy-sm mb-4">{postClass.postTitle}</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 row-gap-8 col-gap-8">
              {postClass.candidates.map((candidate) => (
                <div className="content-block">
{/*
                  <div className="avatar badge__avatar avatar--md">
                    <img src={"/medailonky/" + candidate.img} alt={candidate.name} />
                  </div>
*/}
                  <img
                    src={"/medailonky/" + candidate.img}
                    className="w-40 float-right pl-4 pt-2 pb-4"
                    alt={candidate.name}
                  />
                  <h3 className="head-heavy-xs">{candidate.name}</h3>
                  <a
                    href={candidate.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Nominační řeč
                  </a>
                  <p className="mt-4">{candidate.desc}</p>
                </div>
              ))}
            </div>
          </section>
          {!isLastItem(idx) && <hr className="hr--big" />}
        </React.Fragment>
      ))}
    </article>
  );
};

export default LeaderElection;
