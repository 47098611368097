import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

import Button from "components/Button";
import VenueCard from "components/VenueCard";
import { venues } from "config";
import { partyVenue2 } from "config";
import { partyVenue3 } from "config";


const Party = () => {
  const venueItem=venues[0];
  const venueItem2=partyVenue2;
  const venueItem3=partyVenue3;
  return (
    <article className="w-full">
      <PageTitle>Preparty a Afterparty</PageTitle>

      <Helmet>
        <title>Preparty a Afterparty | {basics.appTitle}</title>
        <meta property="og:title" content="Preparty a Afterparty" />
      </Helmet>

      <div className="content-block">
        <p>
            Brno je městem (takřka) neomezených možností a proto jsme připravili
            celou řadu možností, jak strávit páteční večer, než se v sobotu pustíme do práce:
        </p>

        <h3>Tancuj, tancuj, vykrúcaj!</h3>

        <p>
        Pokud si chcete zatančit, je pro vás připravena tradiční páteční pre-párty,
        k tanci a poslechu zahrají pirátští DJs. Prostor bude otevřen od 19:00,
        DJs začnou hrát od 21:00. Program bude upřesněn.
        </p>

        <p>
        POZOR - novinka - od 20:00 bude pódium volné pro písničkáře s kytarou,
        harmonikou a dalšími nástroji (sólo). Pokud máš zájem si zahrát, napiš na <a href="mailto:ondrej.kapitancik@pirati.cz">ondrej.kapitancik@pirati.cz</a>
        </p>

        <p>
        Vstup pro registrované volný, kapacita akce je 300 lidí.
        </p>

      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        <VenueCard
          street={venueItem.street}
          city={venueItem.city}
          gps={venueItem.gps}
          href={venueItem.href}
          title_short={venueItem.title_short}
        />
      </div>

        <h3>Punkový syndrom</h3>

        <p>
            Tuto možnost využije každý, kdo chce vidět vtipný a zároveň
            hluboce lidský film o obyčejných problémech neobyčejných lidí
            a pak diskutovat o životě lidí s hendikepem. Připravili jsme
            projekci dokumentárního filmu "Punkový syndrom" o punkové kapele
            tvořené muzikanty s mentálním hendikepem. Po filmu bude následovat
            uvolněná debata o sociálních službách a životu s hendikepem v ČR.
        </p>
        <p>
            <strong>Začátek v 19:00.</strong>
            Kontaktní osoba: Radek Holomčík,
            <a href="mailto:radek.holomcik@pirati.cz">radek.holomcik@pirati.cz</a>,
            728 034 460
        </p>
      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        <VenueCard
          street={venueItem2.street}
          city={venueItem2.city}
          gps={venueItem2.gps}
          href={venueItem2.href}
          title_short={venueItem2.title_short}
        />
      </div>

        <h3>Kdo si hraje, netrollí</h3>
        <p>
            Pokud máte rádi deskovky a chcete strávit večer s podobně postiženými
            jedinci, určitě doražte do deskoherny Rishada.
        </p>

        <p>
            <strong>Začátek v 19:00.</strong>
            Kontaktní osoba: Jiří Pertl,
            <a href="jiri.pertl@pirati.cz">jiri.pertl@pirati.cz</a>,
            731 457 522
        </p>

      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        <VenueCard
          street={venueItem3.street}
          city={venueItem3.city}
          gps={venueItem3.gps}
          href={venueItem3.href}
          title_short={venueItem3.title_short}
        />
      </div>

    <h3>Než ho zdaníme...</h3>

    <p>Pokud máte chuť ochutnat moravská vína, je pro vás připravena komentovaná ochutnávka.</p>
    <p>
        Maximální kapacita vinárny je 30 lidí, akce se koná při přihlášení
        alespoň 15 účastníků (informaci o konání akce pošleme všem registrovaným
        ve středu 3. 1.). Plánovaný začátek je v 19:00.
    </p>

    <p>
        <strong>POZOR</strong> - registrace neprobíhá přes registrační systém,
        ale můžete se přihlásit buď na e-mailu <a href="monika.spilkova@pirati.cz">monika.spilkova@pirati.cz</a>,
        nebo na tel. čísle 775 351 483
    </p>
      </div>

      <div className="content-block mt-8">
        <h3>Žádná z uvedených akcí není vhodná pro zlé lidi!</h3>
        <p>
            Pokud jste si nevybrali ani jednu z nabízených možností,
            určitě si vyberete z <a href="https://www.gotobrno.cz/ochutnejte/">pestré nabídky brněnských hospod, kaváren a barů</a>.
        </p>


      </div>

      <div className="content-block mt-8">
        <h2>Afterparty v SONO Centru</h2>
        <p>
            Po skončení oficiální části zasedání budeme v sále SONO Centra pokračovat Afterparty.
            Vystoupí dvě kapely, ve kterých účinkují mj. člen a členka Pirátů od nás z Brna.
            Party zakončí pirátská DJs crew. Konec kolem 1:00 v neděli ráno.
        </p>


        <table>
          <thead>
            <td colspan="2">
            Program / Line-up
            </td>
          </thead>
          <tbody>
          <tr>
            <td>20:30 - 21:30</td>
            <td><a href="https://www.youtube.com/watch?v=vnTrPIPQrSg">Where Are We Now (funk / indie)</a></td>
          </tr>
          <tr>
            <td>22:00 - 23:00</td>
            <td><a href="https://www.youtube.com/watch?v=ovkrd5WKyRE">Tezaura (melodic metal)</a></td>
          </tr>
          <tr>
            <td>23:30 - 01:00</td>
            <td>MΛЯRΛ (house / electroswing)</td>
          </tr>
          </tbody>
        </table>

        <p>
            Pokud nemáte zájem o afterparty s tímto programem, bude možnost posezení
            a pokecu v přilehlé restauraci, případně budeme mít v SONO centru
            vyčleněnou místnost pro kytarovky.
        </p>

      </div>
    </article>
  );
};

export default Party;
