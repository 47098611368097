import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

import Button from "components/Button";
import VenueCard from "components/VenueCard";
import Badge from "components/Badge";
import { venues, organisers } from "config";


const Workshops = () => {
  const venueItem=venues[2]
  const person=organisers[0].members[2];
  return (
    <article className="w-full">
      <PageTitle>Workshopy</PageTitle>

      <Helmet>
        <title>Workshopy | {basics.appTitle}</title>
        <meta property="og:title" content="Workshopy" />
      </Helmet>

      <div className="content-block">
        <h2>Nedělní workshopy v Café Práh</h2>
        <p>
            Na neděli 14. ledna jsme si pro vás připravili sérii workshopů, které proběhnou v <a href="https://www.cafeprah.cz">Café Práh</a> a uzavřou tak víkendové zasedání celostátního fóra.
        </p>
        <p>
Začátek workshopů je naplánován na 9. hodinu ranní a po úvodu od celostátního volebního štábu poběží vždy 2 workshopy souběžně v oddělených přednáškových sálech. Jak název sám napovídá, v prostorech Café Práh najdete i kavárnu, ve které bude možnost se občerstvit a posedět. Připravujeme i dětský koutek, protože kočárková politika nám není cizí!
        </p>

        <p>
Další informace včetně konkrétního programu budeme postupně zveřejňovat.
        </p>

      </div>

      <Button
          className="text-lg btn--to-blue-300"
          bodyClassName="whitespace-no-wrap"
          color="black"
          href="program#neděle 14. 1. 2024"
          icon="ico--chevron-right"
          >
          Harmonogram workshopů
        </Button>

      <div className="content-block p-2">
      </div>

      <div className="grid md:grid-cols-2 gap-16 content-block">
        <VenueCard
          street={venueItem.street}
          city={venueItem.city}
          gps={venueItem.gps}
          href={venueItem.href}
          title_short={venueItem.title_short}
        />
        <Badge
          name={person.name}
          position={person.position}
          avatarUrl={person.avatarUrl}
          phone={person.phone}
          email={person.email}
          key={person.name}
          />
      </div>

    </article>
  );
};

export default Workshops;
